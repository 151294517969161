// alterar arquivo: public/index.html

// checar se existe código do google analytics (apagar ou substituir se for o caso)

// alterar arquivos: .firebaserc (conferir nesse se o default e target apontam para o projeto correto do firebase), firebase.json, package.json e info.txt com o nome do projeto no firebase

// color primary: public/assets/switcher/css/color2.css
// color secondary: public/assets/switcher/css/color2.css: 135

export const id_firebase = "cw2cfh9Jnm0EHWcDg1S5";  // Shopping


export const ids_firebase = [ 
    "2Jc5Cv0NmR0zoJKOD7mE", // Maverick 0
    "10hrB73mIdmXp5QWKOhK",  // Pissarelli 1
    "OtkwZKeDKX4GYJmcObfu",  // Premium 2
    "zhh1I1SMta8uxCkyRM9M",   // Gbr 3
    "uLYQKAJn5Ke8T0s1u1jW",  // Spiner 4
    "kPUFW4aTB0wrZNE7LTvU" // Astro Multimarcas 5
];

    export const href_revenda = [
        "veiculo_maverick",
        "veiculo_pissarelli",
        "veiculo_premium",
        "veiculo_gbr",
        "veiculo_spiner",
        "veiculo_astromultimarcas"
    ];
    